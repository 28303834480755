import UArray from "@repo/utils/UArray";

namespace UEventTarget {
  export const wrap = <Target extends EventTarget>(eventTarget: Target) => {
    const events = {
      // TODO: Fix type
      on<
        EventType extends keyof HTMLElementEventMap,
        // EventType extends any extends typeof eventTarget.addEventListener<
        //   infer T extends keyof HTMLElementEventMap
        // >
        //   ? T
        //   : never,
      >(
        types: UArray.Maybe<EventType>,
        listener: (this: HTMLElement, e: HTMLElementEventMap[EventType]) => any,
        // listener: Parameters<typeof eventTarget.addEventListener<EventType>>[1],
      ) {
        for (const type of [types].flat()) {
          // @ts-expect-error
          eventTarget.addEventListener(type, listener);
        }
        return {
          off() {
            for (const type of [types].flat()) {
              // @ts-expect-error
              eventTarget.removeEventListener(type, listener);
            }
          },
        };
      },
      once<
        EventType extends keyof HTMLElementEventMap,
        // EventType extends any extends typeof eventTarget.addEventListener<
        //   infer T extends keyof HTMLElementEventMap
        // >
        //   ? T
        //   : never,
      >(
        types: UArray.Maybe<EventType>,
        listener: (this: HTMLElement, e: HTMLElementEventMap[EventType]) => any,
        // listener: Parameters<typeof eventTarget.addEventListener<EventType>>[1],
      ) {
        const withOff: typeof listener = (e: any) => {
          res.off();
          // @ts-expect-error
          return listener.bind(eventTarget)(e);
        };

        const res = events.on(types, withOff);
        return res;
      },
    };
    return events;
  };
}

export default UEventTarget;
