import UEventTarget from "./UEventTarget.mjs";

namespace UHTMLScriptElement {
  export const inject = (src: string) =>
    new Promise<Event>((resolve, reject) => {
      const script = document.createElement("script");
      script.defer = true;

      const events = UEventTarget.wrap(script);
      events.once("load", resolve);
      events.once("error", reject);

      script.src = src;
      document.head.appendChild(script);
    });
}

export default UHTMLScriptElement;
